import React, { useState, useEffect, useContext } from "react";
import { gsap } from "gsap";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// hooks
import { Context } from "../../../index";
import { RandomTimeout } from "../../../utils/textHandlers/date";

// Assets
import ringTone from "../../../lib/mp3/incoming-message.mp3";
import ringTone2 from "../../../lib/mp3/happy-popup.mp3";

// Styles
import "./qPopupStyle.scss";

export const QuestionarePopup = () => {
  const [isVisible, setIsVisible] = useState(false); // Початково невидимий
  const { auth, localClaims } = useContext(Context);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // логіка відображення попап
  // доречі,
  // раніше тут булро так :
  //
  // if (
  //   location.pathname !== "/survey" &&
  //   auth.currentUser &&
  //   localClaims.survey !== true
  // ) {

  useEffect(() => {
    const initialDelay = RandomTimeout(15, 60); // Використовуємо RandomTimeout для початкової затримки
    const timeoutId = setTimeout(() => {
      if (location.pathname !== "/survey" && localClaims.survey !== true) {
        setIsVisible(true);
      }
    }, initialDelay);

    return () => clearTimeout(timeoutId); // Очищення таймера при розмонтуванні
  }, [auth.currentUser, localClaims.survey, location.pathname]);

  useEffect(() => {
    if (isVisible) {
      const audio = new Audio(ringTone2);
      // audio.play();

      // Анімація появи контейнера попапу з правої сторони
      gsap.fromTo(
        ".popup",
        { opacity: 0, x: window.innerWidth }, // Початкова позиція за межами правої сторони
        { opacity: 1, x: 0, duration: 0.5 } // Кінцева позиція в центрі
      );
      // Анімація поступової появи тексту
      gsap.fromTo(
        ".popup__text span",
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.1,
          stagger: 0.05, // Поступова поява кожної букви
        }
      );
    }
  }, [isVisible]);

  const closePopup = () => {
    gsap.to(".popup", {
      opacity: 0,
      x: window.innerWidth, // Зникає вправо за межі видимої зони
      duration: 0.5,
      onComplete: () => {
        setIsVisible(false);
        if (localClaims.survey !== true) {
          setTimeout(() => setIsVisible(true), RandomTimeout(180, 300)); // Використовуємо RandomTimeout для повторної затримки
        }
      },
    });
  };

  // а раніше було так :
  // if (
  //   !isVisible ||
  //   !auth.currentUser ||
  //   location.pathname === "/survey" ||
  //   localClaims.survey === true
  // )

  if (
    !isVisible ||
    location.pathname === "/survey" ||
    localClaims.survey === true
  )
    return null;

  return (
    <main className="popup" onClick={() => navigate("/survey")}>
      <section className="popup__content">
        <div className="popup__text">
          {Array.from(t("take_survey1")).map((char, index) => (
            <span key={index}>{char}</span>
          ))}
        </div>

        <div className="popup__text">
          {Array.from(t("take_survey2")).map((char, index) => (
            <span key={index}>{char}</span>
          ))}
        </div>

        <div className="popup__text">
          {Array.from(t("take_survey3")).map((char, index) => (
            <span key={index}>{char}</span>
          ))}
        </div>
      </section>

      <div
        className="popup__wrap"
        onClick={(e) => {
          e.stopPropagation();
          closePopup();
        }}
      >
        <button className="popup__close-btn">&times;</button>
      </div>
    </main>
  );
};
