// Формування дати
export const newDate = new Date()
  .toISOString()
  .split("T")[0]
  .split("-")
  .reverse()
  .join(".");

// Формат дати в формат DD.MM.YYYY
// з нулем перед днями і місяцями
export const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0"); // Додає 0 перед днями < 10
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Місяці від 0 до 11, додаємо 1
  const year = date.getFullYear();
  return `${day}.${month}.${year}`; // Розділювач - крапка
};

// Формат дати в формат DD.MM.YYYY HH:MM:SS
// наприклад 28.11.2024 14:05:30
export const formatDateTime = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

// Формат для версії: YYYY-MM-DD_HH-MM-SS
export const formatDateTime2 = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
};

// Універсальна функція для генерації випадкового значення в мілісекундах
export const RandomTimeout = (minSeconds, maxSeconds) => {
  const min = minSeconds * 1000; // Перетворюємо секунди в мілісекунди
  const max = maxSeconds * 1000;

  return Math.floor(Math.random() * (max - min + 1)) + min;
};
