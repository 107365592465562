import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"; // плагін динамічної загрузки бд

// Отримуємо версію з process.env
const version = process.env.REACT_APP_VERSION || Date.now();

i18n
  .use(Backend)
  .use(initReactI18next) // Передаємо i18n екземпляр до react-i18next.
  .init({
    // Конфігурація backend, щоб вказати, де знаходяться файли перекладів
    backend: {
      loadPath: `/locales/{{lng}}.json?v=${version}`, // Додаємо параметр версії до запиту
    },
    fallbackLng: "en",
    keySeparator: false, // Використовуємо або не потребуємо ключі у формі 'messages.welcome'
    interpolation: {
      escapeValue: false, // React автоматично уникне ін'єкції
    },
    cache: {
      enabled: false, // Вимкнення кешування
    },
    debug: false, // Увімкнення режиму налагодження для перевірки завантаження локалізацій
  });

export default i18n;

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend"; // плагін динамічної загрузки бд

// i18n
//   // Підключення backend плагіна
//   .use(Backend)
//   .use(initReactI18next) // Передаємо i18n екземпляр до react-i18next.
//   .init({
//     // Конфігурація backend, щоб вказати, де знаходяться файли перекладів
//     backend: {
//       loadPath: "/locales/{{lng}}.json", // Вказуємо шлях до ваших файлів локалізації
//     },
//     fallbackLng: "en",
//     keySeparator: false, // Використовуємо або не потребуємо ключі у формі 'messages.welcome'
//     interpolation: {
//       escapeValue: false, // React автоматично уникне ін'єкції
//     },
//     cache: {
//       enabled: false, // Вимкнення кешування
//     },
//     debug: false, // Увімкнення режиму налагодження для перевірки завантаження локалізацій
//   });

// export default i18n;
